import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, Info } from 'lucide-react';
import PageTransition from '../components/PageTransition';

const styleOptions = [
  {
    name: 'Comic Book',
    color: 'bg-red-500',
    description: "Bold, vibrant illustrations reminiscent of classic comic books. Features exaggerated proportions, dynamic poses, and strong contrast.",
    style: "comic book",
    texture: "Flat colors with ben-day dots for shading, creating a print-like appearance. Bold outlines and crosshatching for depth."
  },
  {
    name: 'Realistic',
    color: 'bg-blue-500',
    description: "Highly detailed representations aiming to closely mimic real-life appearance. Emphasizes accurate proportions, lighting, and textures.",
    style: "photorealistic",
    texture: "Smooth blending of colors with subtle variations in tone and hue to replicate real-world surfaces. Fine details included for authenticity."
  },
  {
    name: 'Pixel Art',
    color: 'bg-green-500',
    description: "Digital art form where images are created at the pixel level. Characterized by its deliberately limited resolution and color palette.",
    style: "pixelated",
    texture: "Blocky and deliberately low-resolution. Each pixel is distinct and visible, creating a mosaic-like appearance with sharp edges."
  },
  {
    name: 'Watercolor',
    color: 'bg-purple-500',
    description: "Soft, fluid style mimicking traditional watercolor paintings. Known for its translucent washes, color bleeding, and gentle gradients.",
    style: "watercolor",
    texture: "Grainy, with visible paper texture. Colors blend and bleed into each other, creating soft edges and occasional 'blooms'."
  },
  {
    name: 'Neon',
    color: 'bg-pink-500',
    description: "Vivid, high-contrast style inspired by neon lighting. Features bright, glowing colors against dark backgrounds.",
    style: "neon",
    texture: "Smooth, glossy appearance with a radiant glow effect. Sharp contrasts between illuminated elements and dark backgrounds."
  },
  {
    name: 'Minimalist',
    color: 'bg-yellow-500',
    description: "Clean, simple style that focuses on essential elements. Uses basic shapes, limited color palettes, and negative space.",
    style: "minimalist",
    texture: "Flat, smooth surfaces with little to no texture. Emphasis on solid colors and clean lines, occasionally using subtle gradients for depth."
  }
];

const StyleCard = ({ style, isSelected, onClick }) => (
  <motion.div
    className={`${style.color} p-4 rounded-lg cursor-pointer ${isSelected ? 'ring-4 ring-white' : ''} transition-shadow duration-300 hover:shadow-lg`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    onClick={() => onClick(style)}
    role="button"
    aria-pressed={isSelected}
    tabIndex={0}
    onKeyPress={(e) => e.key === 'Enter' && onClick(style)}
  >
    <h3 className="text-white font-bold text-lg mb-2">{style.name}</h3>
    <p className="text-white text-sm">{style.description.split('.')[0]}.</p>
  </motion.div>
);

const StyleDescription = () => {
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate();

  const handleStyleSelect = (style) => {
    setSelectedStyle(style);
    setShowInfo(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedStyle) {
      sessionStorage.setItem('app_style', JSON.stringify(selectedStyle));
      navigate('/preview');
    } else {
      alert('Please select a style before continuing.');
    }
  };

  return (
    <PageTransition>
      <div className="min-h-screen bg-gradient-to-br from-blue-500 via-purple-500 to-pink-500 flex flex-col justify-center items-center p-8 text-white">
        <motion.h1
          className="text-5xl font-bold mb-8 text-center"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Choose Your Icon Style
        </motion.h1>
        <motion.p
          className="text-xl mb-8 text-center max-w-2xl"
          initial={{ y: -30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Select the style that best fits your vision. Each option offers a unique look for your custom icons.
        </motion.p>
        <form onSubmit={handleSubmit} className="w-full max-w-4xl">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-6 mb-8">
            {styleOptions.map((style) => (
              <StyleCard
                key={style.name}
                style={style}
                isSelected={selectedStyle?.name === style.name}
                onClick={handleStyleSelect}
              />
            ))}
          </div>
          <AnimatePresence>
            {showInfo && selectedStyle && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="bg-white bg-opacity-20 p-6 rounded-lg mb-8 shadow-lg"
              >
                <div className="flex items-center mb-4">
                  <Info size={24} className="mr-2" />
                  <h3 className="font-bold text-xl">{selectedStyle.name} Style Details</h3>
                </div>
                <p className="mb-4">{selectedStyle.description}</p>
                <p><strong>Texture:</strong> {selectedStyle.texture}</p>
              </motion.div>
            )}
          </AnimatePresence>
          <motion.button
            type="submit"
            className="w-full bg-white text-purple-600 font-bold py-4 px-6 rounded-full text-xl hover:bg-purple-100 transition duration-300 ease-in-out flex items-center justify-center shadow-lg focus:outline-none focus:ring-4 focus:ring-purple-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={!selectedStyle}
          >
            Generate Icons with {selectedStyle?.name || 'Selected'} Style <ArrowRight className="ml-2" size={24} />
          </motion.button>
        </form>
      </div>
    </PageTransition>
  );
};

export default StyleDescription;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Sparkles, Zap, Palette, RefreshCw, X } from 'lucide-react';
import PageTransition from '../components/PageTransition';
import LoadingSpinner from '../components/LoadingSpinner';
import ClockAfter from '../images/Clock_after.jpeg';
import ClockTheme from '../images/Clock_theme.webp'

const ThemeDescription = () => {
  // Manage the current step: 'selection', 'description', 'result'
  const [step, setStep] = useState('selection');
  // Track the user's selected option: 'character' or 'theme'
  const [selectedOption, setSelectedOption] = useState(null);
  // Store the user's theme or character description
  const [theme, setTheme] = useState('');
  // Handle loading state during API calls
  const [isLoading, setIsLoading] = useState(false);
  // Toggle the display of theme ideas
  const [showTip, setShowTip] = useState(false);
  // Store the API response
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  // Predefined example themes for user inspiration
  const exampleThemes = [
    "Steampunk Inventor",
    "Magical Underwater Kingdom",
    "Retro 80s Neon Dreamscape",
    "Zen Garden Minimalism",
    "Cosmic Space Explorer"
  ];

  // Select a random theme from the examples
  const getRandomTheme = () => {
    const randomIndex = Math.floor(Math.random() * exampleThemes.length);
    setTheme(exampleThemes[randomIndex]);
  };

  // Handle the selection of icon type and proceed to the description step
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    sessionStorage.setItem('icon_type', option)
    setStep('description');
  };

  // Handle form submission: send data to the backend and display results
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedOption) {
      alert('Please select an icon type.');
      return;
    }

    if (!theme.trim()) {
      alert('Please enter a description.');
      return;
    }

    setIsLoading(true);
    console.log({theme: theme.trim(),
      icon_type: selectedOption,})

    try {
      const response = await fetch('/api/generate_theme', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          theme: theme.trim(),
          icon_type: selectedOption,
        }),
      });

      if (response.ok) {
        const resultData = await response.json();
        sessionStorage.setItem('concept', JSON.stringify(resultData));
        setResult(resultData); // Assuming 'generatedText' is the key
        setStep('result');
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      alert('Something went wrong. Please try again.');
      console.error('API Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Reset the form to allow users to start over
  // const resetForm = () => {
  //   setStep('selection');
  //   setSelectedOption(null);
  //   setTheme('');
  //   setResult(null);
  //   setShowTip(false);
  // };

  return (
    <PageTransition>
      <div className="min-h-screen bg-gradient-to-br from-green-400 via-teal-500 to-blue-500 flex flex-col justify-center items-center p-6 text-white">
        
        {/* Header Section */}
        {step !== 'result' && (
        <motion.div
          className="text-center mb-12"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-4">Craft Your Unique Icon</h1>
          <p className="text-lg md:text-xl max-w-2xl mx-auto">
            Describe your theme or character, and let AI transform your vision into stunning, personalized icons.
          </p>
        </motion.div>
        )}
        
        {/* Main Content */}
        <AnimatePresence>
          {/* Option Selection Step */}
          {step === 'selection' && (
            <motion.div
             
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.5 }}
              key="selection"
            >
              <h2 className="text-2xl font-semibold mb-6">Choose Icon Type</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Character-Based Icon Option */}
                <motion.button
                  onClick={() => handleOptionSelect('character')}
                  className="flex flex-col items-center bg-teal-600 bg-opacity-80 hover:bg-teal-700 transition-colors duration-300 p-6 rounded-lg shadow-md"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  aria-label="Select Character-Based Icon"
                >
                  {/* Example Icon */}
                  <img
                    src={ClockAfter} // Ensure this path is correct
                    alt="Character-Based Icon Example"
                    className="w-24 h-24 mb-4 object-contain rounded-lg border-2 border-white"
                  />
                  <h3 className="text-xl font-bold mb-2">Character-Based Icon</h3>
                  <p className="text-center text-sm">
                    An icon featuring a character that embodies the app's functionality.
                  </p>
                </motion.button>

                {/* Theme-Incorporated Icon Option */}
                <motion.button
                  onClick={() => handleOptionSelect('theme')}
                  className="flex flex-col items-center bg-purple-600 bg-opacity-80 hover:bg-purple-700 transition-colors duration-300 p-6 rounded-lg shadow-md"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  aria-label="Select Theme-Incorporated Icon"
                  
                >
                  {/* Example Icon */}
                  <img
                    src={ClockTheme}
                    alt="Theme-Incorporated Icon Example"
                    className="w-24 h-24 mb-4 object-contain rounded-lg border-2 border-white"
                  />
                  <h3 className="text-xl font-bold mb-2">Theme-Incorporated Icon</h3>
                  <p className="text-center text-sm">
                    An icon that seamlessly integrates with the app's overall theme.
                  </p>
                  
                </motion.button>
              </div>
            </motion.div>
          )}

          {/* Description Input Step */}
          {step === 'description' && (
            <motion.form
              onSubmit={handleSubmit}
              className='space-y-6 w-full max-w-md'
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.5 }}
              key="description"
            >
              <h2 className="text-2xl font-semibold mb-6">Describe Your {selectedOption === 'character' ? 'Character' : 'Theme'}</h2>
              <div className="mb-6 relative">
                <textarea
                  value={theme}
                  onChange={(e) => setTheme(e.target.value)}
                  placeholder={
                    selectedOption === 'character'
                      ? "E.g., 'Cyberpunk Hacker' or 'Whimsical Forest Creature'"
                      : "E.g., 'Neon Cyberpunk Theme' or 'Zen Garden Background'"
                  }
                  className="w-full p-4 rounded-lg shadow-inner focus:ring-2 focus:ring-blue-400 focus:outline-none text-gray-800 text-lg transition-shadow duration-300 resize-none"
                  rows="5"
                  aria-label="Theme or Character Description"
                />
                {/* Toggle Theme Ideas */}
                <motion.button
                  type="button"
                  className="absolute right-4 top-4 text-gray-300 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-400 rounded-full p-2"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => setShowTip(!showTip)}
                  aria-label="Toggle Theme Ideas"
                >
                  <Sparkles size={24} />
                </motion.button>
                {/* Get Random Theme */}
                <motion.button
                  type="button"
                  className="absolute right-4 bottom-4 text-gray-300 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-400 rounded-full p-2"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={getRandomTheme}
                  aria-label="Get Random Theme"
                >
                  <RefreshCw size={24} />
                </motion.button>
              </div>

              {/* Theme Ideas Tooltip */}
              <AnimatePresence>
                {showTip && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="bg-white bg-opacity-90 text-gray-800 p-4 rounded-lg mb-6 shadow-lg"
                    key="theme-ideas"
                  >
                    <h3 className="font-bold mb-2 text-lg">Inspiration:</h3>
                    <ul className="list-disc list-inside">
                      {exampleThemes.map((themeIdea, index) => (
                        <li
                          key={index}
                          className="cursor-pointer hover:text-blue-600 transition-colors duration-200"
                          onClick={() => setTheme(themeIdea)}
                        >
                          {themeIdea}
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </AnimatePresence>

              {/* Action Buttons */}
              <div className="flex flex-col justify-between items-center">
                <motion.button
                  type="submit"
                  className="w-full mb-4 bg-white text-purple-600 font-bold py-4 px-6 rounded-full text-lg hover:bg-purple-100 transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center shadow-lg"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={isLoading || theme.trim() === ''}
                  aria-label="Generate Icon Theme"
                >
                  {isLoading ? <LoadingSpinner /> : (
                    <>
                      Generate {selectedOption === 'character' ? 'Character' : 'Theme'} <Zap className="ml-2" size={24} /> 
                    </>
                  )}
                </motion.button>

                <motion.button
                  type="button"
                  onClick={() => setStep('selection')}
                  className="w-full bg-white text-purple-600 font-bold py-4 px-6 rounded-full text-lg hover:bg-purple-100 transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center shadow-lg"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  aria-label="Go Back to Selection"
                >
                  Back <X size={20} className="ml-2" /> 
                </motion.button>
              </div>
            </motion.form>
          )}

          {/* Result Display Step */}
          {step === 'result' && result && (
            <motion.div
              className="space-y-6 w-full max-w-md"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.5 }}
              key="result"
            >
              <h2 className="text-3xl font-semibold mb-6">Success!</h2>
              <p className="text-lg mb-6">
                Your {selectedOption === 'character' ? 'Character Concept' : 'Theme Description'} has been generated.
              </p>
              <motion.div
                className="bg-teal-600 bg-opacity-80 text-white p-6 rounded-lg mb-6 shadow-md"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key="generated-text"
              >
                <p className="text-center">
                  <span className="font-bold">Generated {selectedOption === 'character' ? 'Character' : 'Theme'}:</span>
                  <br />
                  "{result}"
                </p>
              </motion.div>
              <div className="flex flex-col justify-between items-center">
               
                <motion.button
                  onClick={() => navigate('/style')}
                  className="w-full mb-4 bg-white text-purple-600 font-bold py-4 px-6 rounded-full text-lg hover:bg-purple-100 transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center shadow-lg"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  aria-label="Proceed to Style"
                >
                   Proceed <Palette size={20} className="ml-2" />
                </motion.button>

                <motion.button
                  onClick={() => setStep('description')}
                  className="w-full bg-white text-purple-600 font-bold py-4 px-6 rounded-full text-lg hover:bg-purple-100 transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center shadow-lg"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  aria-label="Regenerate Theme"
                >
                  Back <X size={20} className="ml-2" />
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Footer Section */}
        {step !== 'result' && (
          <motion.div
            className="mt-12 text-center"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6 }}
          >
            <p className="text-lg mb-4">
              Let your imagination soar! Our AI can handle any theme, from sci-fi to fantasy, minimalist to maximalist.
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Palette size={24} />
              <p>Unlimited creative possibilities await!</p>
            </div>
          </motion.div>
        )}
      </div>
    </PageTransition>
  );
};

export default ThemeDescription;

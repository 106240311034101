import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, RefreshCw, ThumbsUp } from 'lucide-react';
import PageTransition from '../components/PageTransition';
import LoadingSpinner from '../components/LoadingSpinner';
import { useApi } from '../hooks/useApi';

const Preview = () => {
  const [previewIcon, setPreviewIcon] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const navigate = useNavigate();
  const { apiCall } = useApi();
  const fetchPreviewIcon = useCallback(async () => {
    setIsLoading(true);
    const app_style = JSON.parse(sessionStorage.getItem('app_style'));
    const concept = JSON.parse(sessionStorage.getItem('concept'));
    const apps = JSON.parse(sessionStorage.getItem('apps'));
    const app_name = apps[0]
    const random_seed = Math.floor(Math.random() * 1000000);
    // store random seed in session storage
    sessionStorage.setItem('random_seed', random_seed);

    if (!app_style || !concept) {
      console.error('Missing app style or concept');
      alert('Oops! Something went wrong. Let\'s start over.');
      navigate('/theme');
      return;
    }

    if (sessionStorage.getItem('preview_icon')) {
      setPreviewIcon(sessionStorage.getItem('preview_icon'));
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch('/api/generate_icon_preview', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          app_name: app_name,
          app_style: app_style,
          concept: concept,
          random_seed: random_seed,
          iconSetId: null,
        }),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      sessionStorage.setItem('preview_icon', data[0]);
      setPreviewIcon(data[0]);
    } catch (error) {
      console.error('Failed to fetch data from API:', error);
      alert('Oops! We couldn\'t generate your icon. Let\'s try again!');
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchPreviewIcon();
  }, [fetchPreviewIcon]);

  const handleContinue = async () => {
    // create icon set
    // Check is user authenticated
    // if not, redirect to /email-signup
    // if yes, continue
    if (sessionStorage.getItem('preview_icon')) {
      const random_seed = sessionStorage.getItem('random_seed');
      const app_style = sessionStorage.getItem('app_style');
      const apps = sessionStorage.getItem('apps');
      const app_name = JSON.parse(apps)[0];
      const concept = sessionStorage.getItem('concept');
      const preview_icon = sessionStorage.getItem('preview_icon');
      const icon_type = sessionStorage.getItem('icon_type');
      console.log({ random_seed, app_style, concept, apps, app_name, preview_icon, icon_type });
      if (random_seed && app_style && concept) {
        const response = await apiCall('api/icon-sets', {
          method: 'POST',
          body: JSON.stringify({ random_seed, app_style, concept, apps, icon_type }),
        });
        if (response.ok) {
          const data = await response.json();
          const iconSetId = data.id;
          sessionStorage.removeItem('random_seed');
          sessionStorage.removeItem('app_style');
          sessionStorage.removeItem('concept');
          // sessionStorage.removeItem('apps');
          console.log('iconSetId', iconSetId);
          console.log('preview_icon', preview_icon);
          console.log('app_name', app_name);
          const iconData = await apiCall('api/icons', {
            method: 'POST',
            body: JSON.stringify({ iconSetId: iconSetId, appName: app_name, iconUrl: preview_icon }),
          });
          if (iconData.ok) {
            sessionStorage.removeItem('preview_icon');
            sessionStorage.removeItem('app_name');
            navigate(`/icon_set/${iconSetId}`);
          } else {
            console.error('Error creating icon:', iconData.error);
          }
        } else {
          console.error('Error creating icon set:', response.error);
        }
      }
    }

    
  };

  const handleRegenerateIcon = () => {
    sessionStorage.removeItem('preview_icon');
    fetchPreviewIcon();
  };

  return (
    <PageTransition>
      <div className="min-h-screen bg-gradient-to-br from-green-500 via-teal-500 to-blue-500 flex flex-col justify-center items-center p-8 text-white">
        <motion.h1
          className="text-5xl font-bold mb-8"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Your Custom Icon Awaits!
        </motion.h1>

        <AnimatePresence mode="wait">
          {isLoading ? (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col items-center"
            >
              <LoadingSpinner size={60} />
              <p className="mt-4 text-xl">Crafting your unique icon...</p>
            </motion.div>
          ) : previewIcon && (
            <motion.div
              key="preview"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col items-center"
            >
              <img src={previewIcon} alt="Preview Icon" className="w-64 h-64 rounded-3xl shadow-2xl mb-8" />
              <p className="text-2xl mb-6">Here's your AI-generated custom icon. What do you think?</p>
              <div className="flex space-x-4 mb-8">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => setShowFeedback(true)}
                  className="bg-white text-green-500 p-3 rounded-full"
                >
                  <ThumbsUp size={24} />
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={handleRegenerateIcon}
                  className="bg-white text-red-500 p-3 rounded-full"
                >
                  <RefreshCw size={24} />
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showFeedback && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="text-center mb-8"
            >
              <p className="text-2xl mb-4">Great! Ready to see more?</p>
              <motion.button
                onClick={handleContinue}
                className="bg-white text-teal-600 font-bold py-3 px-6 rounded-full text-lg hover:bg-teal-100 transition duration-300 ease-in-out flex items-center"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Generate All Icons <ArrowRight className="ml-2" size={24} />
              </motion.button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </PageTransition>
  );
};

export default Preview;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { ArrowRight, Sparkles, Zap, Palette, ChevronLeft, ChevronRight } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import PageTransition from '../components/PageTransition';
import TransformationPreview from '../components/TransformationPreview';
import HeroSection from '../components/HeroSection';
import { Helmet } from 'react-helmet-async';

const FeatureCard = ({ icon: Icon, title, description, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      className="bg-white bg-opacity-10 rounded-xl p-6 text-center hover:bg-opacity-20 transition-all duration-300 transform hover:scale-105 hover:shadow-xl"
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 }
      }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <Icon className="mx-auto mb-4 text-yellow-400" size={48} />
      <h3 className="text-2xl font-semibold mb-2">{title}</h3>
      <p className="text-lg">{description}</p>
    </motion.div>
  );
};

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const transformationExamples = [
    { before: '/path/to/before1.png', after: '/path/to/after1.png', description: "From boring to extraordinary!" },
    { before: '/path/to/before2.png', after: '/path/to/after2.png', description: "Unique style, just for you!" },
    { before: '/path/to/before3.png', after: '/path/to/after3.png', description: "Stand out from the crowd!" },
  ];

  return (
    <PageTransition>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>Transform Your iPhone with AI-Powered Custom Icons | Custom Icons</title>
        <meta
          name="description"
          content="Make your iPhone uniquely yours with AI-generated custom app icons. Transform your phone's look in minutes!"
        />
        <meta name="keywords" content="iPhone, custom icons, AI, app icons, personalization" />
        <meta name="author" content="Custom Icons" />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content="Transform Your iPhone with AI-Powered Custom Icons | Custom Icons" />
        <meta
          property="og:description"
          content="Make your iPhone uniquely yours with AI-generated custom app icons. Transform your phone's look in minutes!"
        />
        <meta property="og:url" content="https://www.customicons.ai/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/images/logo.webp" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="customicons.ai" />
        <meta property="twitter:url" content="https://www.customicons.ai/" />
        <meta name="twitter:title" content="Transform Your iPhone with AI-Powered Custom Icons | Custom Icons" />
        <meta
          name="twitter:description"
          content="Make your iPhone uniquely yours with AI-generated custom app icons. Transform your phone's look in minutes!"
        />
        <meta name="twitter:image" content="/images/logo.webp" />

        {/* Canonical Link */}
        <link rel="canonical" href="https://www.customicons.ai/" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-600 text-white overflow-hidden">
        {/* Hero Section */}
        <HeroSection />

        {/* How It Works Section */}
        <section className="px-4 py-16 sm:px-6 lg:px-8 bg-purple-800 bg-opacity-30">
          <h2 className="text-4xl font-bold text-center mb-12">How It Works</h2>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-12">
            {[
              { step: 1, text: "Choose your style" },
              { step: 2, text: "Describe your vision" },
              { step: 3, text: "Let AI create your icons" },
              { step: 4, text: "Install & enjoy!" },
            ].map((item, index) => (
              <div key={index} className="flex flex-col items-center">
                <div className="w-16 h-16 rounded-full bg-white text-purple-600 flex items-center justify-center text-2xl font-bold mb-4">
                  {item.step}
                </div>
                <p className="text-xl text-center">{item.text}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Before and After Section */}
        <section className="px-4 py-16 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-center mb-12">See the Magic Happen</h2>
          <div className="relative max-w-4xl mx-auto">
            <TransformationPreview
              beforeImage={transformationExamples[currentSlide].before}
              afterImage={transformationExamples[currentSlide].after}
            />
            <p className="text-2xl text-center mt-6">{transformationExamples[currentSlide].description}</p>
            <button
              onClick={() => setCurrentSlide((prev) => (prev - 1 + transformationExamples.length) % transformationExamples.length)}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full"
            >
              <ChevronLeft className="text-purple-600" size={32} />
            </button>
            <button
              onClick={() => setCurrentSlide((prev) => (prev + 1) % transformationExamples.length)}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full"
            >
              <ChevronRight className="text-purple-600" size={32} />
            </button>
          </div>
        </section>

        {/* Features Section */}
        <section className="px-4 py-16 sm:px-6 lg:px-8 bg-purple-700 bg-opacity-30">
          <h2 className="text-4xl font-bold text-center mb-12">Why You'll Love Our Icon Creator</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {[
              { icon: Sparkles, title: "AI-Powered Magic", description: "Our advanced AI creates stunning, unique icons that perfectly match your style and vision." },
              { icon: Zap, title: "Lightning Fast", description: "Transform your entire iPhone in minutes, not hours. Quick, easy, and fun!" },
              { icon: Palette, title: "Endless Possibilities", description: "From minimalist to wild and creative, the only limit is your imagination." }
            ].map((feature, index) => (
              <FeatureCard key={index} {...feature} index={index} />
            ))}
          </div>
        </section>

        {/* Call-to-Action Section */}
        <section className="px-4 py-16 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-bold mb-6">Ready for a Phone that's Truly Yours?</h2>
          <p className="max-w-2xl mx-auto text-2xl mb-8">
            Join thousands of happy users who've already transformed their iPhone experience. Your perfect icons are just a few clicks away!
          </p>
          <div className="flex flex-col items-center space-y-4">
            <Link
              to="/upload"
              className="inline-flex items-center px-10 py-5 bg-white text-purple-600 font-bold text-xl rounded-full hover:bg-purple-100 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
            >
              Start Creating Your Icons Now <ArrowRight className="ml-2" size={24} />
            </Link>
            <p className="text-lg mt-4">No credit card required • 100% satisfaction guaranteed</p>
            {/* <div className="flex space-x-4 mt-8">
              <img src="/path/to/trustbadge1.png" alt="Trust Badge 1" className="h-16" />
              <img src="/path/to/trustbadge2.png" alt="Trust Badge 2" className="h-16" />
            </div> */}
          </div>
        </section>
      </div>
    </PageTransition>
  );
};

export default Home;